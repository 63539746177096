import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";

import Typography from "@mui/material/Typography";
import Grid2 from '@mui/material/Grid2';
import * as EMPLOYERS from "../../actions/Employers";
import {verifyEmail} from "../../actions/Employers";
import {me} from "../../actions/Profile";
import SnackBar from "../../components/SnackBar";
import Divider from "@mui/material/Divider";
import Wrapper from "../../layout/Logged/Wrapper";
import {
	checkCompanyExist,
	createCompanyInAdvisor,
	getDirectors,
	requestAccessToEmployer,
	setLoading
} from "../../actions/SignUp";
import LoadingPanel from "../../components/LoadingPanel";

import TopBannerGrid from "../../components/ConfirmCompany/TopBannerGrid";
import CompanyTitle from "../../components/ConfirmCompany/FormControllers/CompanyTitle";
import CompanyAddress from "../../components/ConfirmCompany/FormControllers/CompanyAddress";
import CompanyOfficers from "../../components/ConfirmCompany/FormControllers/CompanyOfficers";
import CompanyStagingDate from "../../components/ConfirmCompany/FormControllers/CompanyStagingDate";
import ManualDirectorsInsertion from "../../components/ConfirmCompany/FormControllers/ManualDirectorsInsertion";
import WrongInfo from "../../components/ConfirmCompany/FormControllers/WrongInfo";
import DirectorsInfo from "../../components/ConfirmCompany/FormControllers/DirectorsInfo";
import AddingConfirmations from "../../components/ConfirmCompany/FormControllers/AddingConfirmations";
import ProceedAddition from "../../components/ConfirmCompany/FormControllers/ProceedAddition";
import PropTypes from "prop-types";
import {useLocation, useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmCompany = (props = {
	_renderFieldsTradingAddress: () => {
	},
	history: {
		push: () => {
		}
	},
	profile_data: {
		is_b2b_b2c: false,
	},
	location: {
		state: {
			employerAttributes: {
				manual_creation: false,
			}
		}
	},
	verifyEmail: () => {
	},
	viewCompany: () => {
	},
	canProceed: false,
}) => {

	const {
			_renderFieldsTradingAddress = () => {
			},
		} = props,
		[directorList, setDirector] = useState([
			{
				date_of_birth: {
					month: 8,
					year: 1980,
				},
				name: "",
			},
		]),
		[
			stagingDate,
			setStagingDate,
		] = useState(''),
		[
			textEmail = '',
			setTextEmail,
		] = useState(''),
		[
			textConfirmEmail = '',
			setTextConfirmEmail,
		] = useState(''),
		[
			textFirstName,
			setTextFirstName,
		] = useState(""),
		[
			textSurName,
			setTextSurName,
		] = useState(''),
		[
			checkBox,
			setCheckBox,
		] = useState(true),
		[
			is_employer_under_bureau,
			setIsUmbrellaCompany
		] = useState(false),
		[
			terms_accepted,
			setTermsAccepted
		] = useState(false),
		[
			telephone,
			setTelephone
		] = useState(""),
		dispatch = useDispatch(),
		history = useNavigate(),
		location = useLocation();

	let isFormLoading = false,
		company_number = JSON.parse(sessionStorage.getItem("EmployerObject"))?.company_number ?? "",
		items_per_page = 0,
		timeout = useRef(null);

	useEffect(() => {
		if (textEmail !== "") {
			if (timeout) {
				timeout.current = clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				dispatch(verifyEmail({
					advisors: {
						primary_email: textEmail ?? "",
					}
				}));
			}, 1000);
		}
	}, [
		textEmail,
		dispatch,
	]);

	useEffect(() => {
		const queryString = {
			company_number,
			items_per_page,
		};
		if (!location?.state?.employerAttributes?.manual_creation) {
			dispatch(getDirectors(queryString));
		}
		dispatch(checkCompanyExist(company_number));
	}, [
		dispatch,
		company_number,
		items_per_page,
		location?.state?.employerAttributes?.manual_creation,
	]);

	if (props?.officers && location?.state?.employerAttributes?.manual_creation === undefined) {
		if (props.officers.length === 0) {
			isFormLoading = true;
			if (isFormLoading) return (
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					style={{
						width: "100%",
					}}
					container
				>
					<LoadingPanel/>
				</Grid2>
			);
		}
	}
	let companyExist = props.checkCompany;

	const nextSuccessPageExistCompany = () => {
		dispatch(requestAccessToEmployer({
			advisors: {
				company_number,
				is_employer_under_bureau
			}
		}));
		history("/success", {
			state: {
				exists: companyExist,
				companyName: JSON.parse(sessionStorage.getItem("EmployerObject")).title,
				is_employer_under_bureau,
			}
		});
	}

	const nextSuccessPageNoExistCompany = () => {
		const data = {
			company_number: JSON.parse(sessionStorage.getItem("EmployerObject")).company_number,
			nickname: JSON.parse(sessionStorage.getItem("EmployerObject")).title,
			primary_email: textEmail,
			firstname: textFirstName,
			surname: textSurName,
			email: textEmail,
			billing_type: "pull",
			setup: "email",
			staging_date: stagingDate,
			callback_url: "https://www.collegia.co.uk",
			is_employer_under_bureau,
			terms_accepted: terms_accepted,
			send_email: false,
			is_via_advisor: true,
			advisor_id: props?.profile_data?.userLogged?.advisor_id,
			deal_owner: props?.profile_data?.deal_owner?.forename ? props?.profile_data?.deal_owner?.forename + " " + props?.profile_data?.deal_owner?.surname : "N/A",
			advisor: props?.profile_data?.title,
			account_manager: props?.profile_data?.account_manager,
			director: {
				name: textFirstName + " " + textSurName,
				email: textEmail,
				phone_number: telephone,
			},
		};
		dispatch(setLoading(true));
		dispatch(createCompanyInAdvisor(data, false));
		history("/success", {
			state: {
				send_email: false,
				exists: companyExist,
				companyName: JSON.parse(sessionStorage.getItem("EmployerObject")).title,
			},
		});
	}

	const nextSuccessPageNoExistCompany1 = () => {
		const data = {
			company_number: JSON.parse(sessionStorage.getItem("EmployerObject")).company_number,
			nickname: JSON.parse(sessionStorage.getItem("EmployerObject")).title,
			primary_email: textEmail,
			firstname: textFirstName,
			surname: textSurName,
			email: textEmail,
			billing_type: "pull",
			setup: "email",
			staging_date: stagingDate,
			callback_url: "https://www.collegia.co.uk",
			is_employer_under_bureau,
			terms_accepted: terms_accepted,
			send_email: true,
			is_via_advisor: true,
			advisor_id: props?.profile_data?.userLogged?.advisor_id,
			deal_owner: props?.profile_data?.deal_owner?.forename ? props?.profile_data?.deal_owner?.forename + " " + props?.profile_data?.deal_owner?.surname : "N/A",
			advisor: props?.profile_data?.title,
			account_manager: props?.profile_data?.account_manager,
			director: {
				name: textFirstName + " " + textSurName,
				email: textEmail,
				phone_number: telephone,
			},
		};
		dispatch(setLoading(true));
		dispatch(createCompanyInAdvisor(data, true));
		history("/success", {
			state: {
				exists: companyExist,
				send_email: true,
				companyName: JSON.parse(sessionStorage.getItem("EmployerObject")).title,
			}
		});
	}

	return (
		<Wrapper history={history}>
			<TopBannerGrid/>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				id={"confirm-new-company-form-container"}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				spacing={0}
				container
				item
				style={{background: "#ffff"}}
			>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					id={"signup-container-detail"}
					alignItems={"center"}
					alignContent={"center"}
					direction={"column"}
					spacing={0}
					container
					item
					style={{background: "#FBFBFB", padding: 20, marginBottom: 15}}
				>
					<form noValidate autoComplete="off">
						<CompanyTitle history={history}/>
						<CompanyAddress history={history}/>
						<CompanyOfficers officers={props?.officers ?? []} companyExist={companyExist}/>
						<CompanyStagingDate
							companyExist={companyExist}
							stagingDate={stagingDate}
							setStagingDate={setStagingDate}
						/>

						<Typography
							style={companyExist ? {display: "none"}
								:
								{display: "block", fontWeight: "bold", fontSize: 16, marginBottom: 15}}
							align={"left"}
						>
							A director or authorised signatory of the company will need to sign
							a participation agreement with Collegia.
						</Typography>
						<ManualDirectorsInsertion
							companyExist={companyExist}
							textFirstName={textFirstName}
							textSurName={textSurName}
							textEmail={textEmail}
							textConfirmEmail={textConfirmEmail}
							setTextFirstName={setTextFirstName}
							setTextSurName={setTextSurName}
							setTextEmail={setTextEmail}
							setTextConfirmEmail={setTextConfirmEmail}
							verifyEmail={props?.emailVerifyErrorMessages}
							emailVerified={props?.emailVerified}
							telephone={telephone}
							setTelephone={setTelephone}
						/>

						{_renderFieldsTradingAddress(props ?? false)}

						<DirectorsInfo
							directors={props?.directors ?? []}
							directorList={directorList}
							setDirector={setDirector}
						/>

						<Divider style={{margin: "10px 0"}}/>

						<AddingConfirmations
							companyExist={companyExist}
							setCheckBox={setCheckBox}
							checkBox={checkBox}
							setIsUmbrellaCompany={setIsUmbrellaCompany}
							is_employer_under_bureau={is_employer_under_bureau}
							setTermsAccepted={setTermsAccepted}
							terms_accepted={terms_accepted}
							profile_data={props?.profile_data}
						/>

						<ProceedAddition
							companyExist={companyExist}
							checkBox={checkBox}
							terms_accepted={terms_accepted}
							textFirstName={textFirstName}
							textSurName={textSurName}
							textEmail={textEmail}
							textConfirmEmail={textConfirmEmail}
							telephone={telephone}
							stagingDate={stagingDate}
							nextSuccessPageExistCompany={nextSuccessPageExistCompany}
							nextSuccessPageNoExistCompany={nextSuccessPageNoExistCompany}
							nextSuccessPageNoExistCompany1={nextSuccessPageNoExistCompany1}
							canProceed={props?.canProceed ?? false}
						/>
						<WrongInfo
							history={history}
						/>
					</form>
				</Grid2>
			</Grid2>
		</Wrapper>
	);
}

ConfirmCompany.propTypes = {
	me: PropTypes.func,
	setTriggerUpload: PropTypes.func.isRequired,
	warn: PropTypes.func.isRequired,
	sendFile: PropTypes.func.isRequired,
	getDirectors: PropTypes.func.isRequired,
	_renderFieldsTradingAddress: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	profile_data: PropTypes.shape({
		is_b2b_b2c: PropTypes.bool.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			employerAttributes: PropTypes.shape({
				manual_creation: PropTypes.bool,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	officers: PropTypes.array,
	directors: PropTypes.array,
	checkCompany: PropTypes.bool,
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.SignUp,
		...state.Employer,
	}),
	(dispatch) => ({
		me: dispatch(me()),
		setTriggerUpload: (shouldTrigger) =>
			dispatch(EMPLOYERS.setTriggerUpload(shouldTrigger)),
		warn: (msg) => dispatch(SnackBar.warn(msg)),
		sendFile: (files = [], employer) =>
			dispatch(EMPLOYERS.insert({papdisFile: files[0], employer})),
		getDirectors: (data = {}) => dispatch(getDirectors(data)),
		verifyEmail: (data = {}) => dispatch(verifyEmail(data)),
	}),
)(ConfirmCompany);
